import React from "react";
import {getCurrentUser, getSelectedOrganizationId} from "../components/CommonFunctions";

const token = localStorage.getItem('token');
const currentUser = getCurrentUser();

const prepareModel = (model) => {
    if(model.newModel !== undefined && model.newModel) {
        return {name: model.name};
    } else {
        return process.env.REACT_APP_API_URI + `models/${model.id}`;
    }
}

export const postPrinters = async (data) => {

    let technology = JSON.parse(data.technology);
    let model = prepareModel(JSON.parse(data.model));
    let extruder = 1;

    if (data.extruder) {
        extruder = 2
    }
    /* TODO: add user organization group/orgnization*/
    await fetch(process.env.REACT_APP_API_ADDRESS + 'printers', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            organization: process.env.REACT_APP_API_URI + `organizations/${getSelectedOrganizationId()}`,
            current_organization: process.env.REACT_APP_API_URI + `organizations/${getSelectedOrganizationId()}`,
            brand: process.env.REACT_APP_API_URI + `printer_brands/${data.brand}`,
            model: model,
            serial_number: data.serial_number,
            name: data.name,
            printable_width: parseInt(data.printableWidth),
            printable_length: parseInt(data.printableLength),
            printable_height: parseInt(data.printableHeight),
            technology: process.env.REACT_APP_API_URI + `technologies/${technology.id}`,
            extruders_number: extruder,
            diameter: data.diameter ? parseInt(data.diameter) : 0,
            controllable_printer_id: data.controllable_printer_id,
            is_fiber: data.is_fiber ? data.is_fiber : false
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const patchPrinters = async (dataPrinter) => {

    let technology = JSON.parse(dataPrinter.technology);
    let model = prepareModel(JSON.parse(dataPrinter.model));

    let extruder = 1;

    if (dataPrinter.extruder) {
        extruder = 2
    }
    /* TODO: add user organization group/orgnization*/
    await fetch(process.env.REACT_APP_API_ADDRESS + `printers/${dataPrinter.id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/merge-patch+json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
            organization_group: process.env.REACT_APP_API_URI + `organization_groups/${currentUser.organization.organization_group.id}`,
            current_organization: process.env.REACT_APP_API_URI + `organizations/${currentUser.organization.id}`,
            brand: process.env.REACT_APP_API_URI + `printer_brands/${dataPrinter.brand}`,
            //model: process.env.REACT_APP_API_URI + `models/${dataPrinter.model}`,
            model: model,
            serial_number: dataPrinter.serial_number,
            name: dataPrinter.name,
            printable_width: parseInt(dataPrinter.printableWidth),
            printable_length: parseInt(dataPrinter.printableLength),
            printable_height: parseInt(dataPrinter.printableHeight),
            technology: process.env.REACT_APP_API_URI + `technologies/${technology.id}`,
            extruders_number: extruder,
            diameter: parseInt(dataPrinter.diameter) ? parseInt(dataPrinter.diameter) : 0,
            controllable_printer_id: dataPrinter.controllable_printer_id,
            controllable_printer_ip: dataPrinter.controllable_printer_ip + '/api/v1', // TODO Manage non-Ultimaker cases
            controllable_printer_credentials: dataPrinter.controllable_printer_credentials,
            is_fiber: dataPrinter.is_fiber ? dataPrinter.is_fiber : false
        })
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const deletePrinters = async (printer) => {

    await fetch(process.env.REACT_APP_API_ADDRESS + `printers/${printer}`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${token}`
        },
    }).then((response) => {
        if(!response.ok)
            throw Error;
    })
}

export const postUploadPrinter = async (data) => {

    let printerFileId;
    const printerId = data.id;
    const formData  = new FormData();
    formData.append('file', data.file[0]);

    await fetch(process.env.REACT_APP_API_ADDRESS + `media_objects?page=1`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: formData
    })
        .then(response => response.json())
        .then(data => {
            printerFileId = data.id;
            fetch(process.env.REACT_APP_API_ADDRESS + `media_objects/${printerFileId}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/merge-patch+json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    printer: process.env.REACT_APP_API_URI + `printers/${printerId}`,
                })
            })
        });
}
